*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: var(--font-main);
	font-size: var(--font-size);
	@include fluid-type($min_width, $max_width, 16px, 24px);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
	color: var(--text);
	overflow-x: hidden;
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);

	&.overflow {
		overflow: hidden;
	}
}

h1 {
	margin: 0 0 15% 0;
	padding: 0;
	font-family: var(--font-title);
	font-weight: 600;
	line-height: 1;
}

h2 {
	margin: 0 0 10% 0;
	padding: 0;
	font-family: var(--font-title);
	font-weight: 600;
	line-height: 1;
}

h3 {
	margin: 0 0 1rem 0;
	padding: 0;
	font-family: var(--font-main);
	font-weight: 600;
}

h4 {
	margin: 0 0 1rem 0;
	padding: 0;
	font-family: var(--font-main);
	font-weight: 600;
}

h5 {
	margin: 0 0 1rem 0;
	padding: 0;
	font-family: var(--font-main);
	font-weight: 600;
}

p {
	margin: 0 0 1.5rem 0;
	color: var(--text);

	@include media-md-down {
		margin: 0 0 1rem 0;
	}

	&:last-child {
		margin: 0;
	}
}

[data-title] {
	display: block;
}

[data-title="big"] {
	font-size: 8rem;
	line-height: 1;
}

[data-title="normal"] {
	font-size: 5vw;
	line-height: 1;
}

[data-color="white"] {
	color: $white;
}

[data-color="yellow"] {
	color: var(--color-yellow);
}

[data-color="purple2"] {
	color: var(--color-purple-2);
}

[data-color="blue"] {
	color: var(--color-blue);
}

[data-color="purple"] {
	color: var(--color-purple);
}

[data-color="red"] {
	color: var(--color-red);
}

[data-fill="white"] {
	fill: $white;
}

[data-fill="yellow"] {
	fill: var(--color-yellow);
}

[data-fill="purple2"] {
	fill: var(--color-purple-2);
}

[data-fill="blue"] {
	fill: var(--color-blue);
}

[data-fill="purple"] {
	fill: var(--color-purple);
}

[data-fill="red"] {
	fill: var(--color-red);
}

.title {
	font-family: var(--font-title);
	font-size: 100px;
	font-weight: 600;
	text-transform: uppercase;
}

b,
strong,
.bold {
	font-weight: 800;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}
}

.mt-1 {
	margin-block-start: 1rem !important;
}

.mt-2 {
	margin-block-start: 2rem !important;
}

.mt-3 {
	margin-block-start: 3rem !important;
}

.mt-4 {
	margin-block-start: 4rem !important;
}

.mt-5 {
	margin-block-start: 5rem !important;
}

.mb-1 {
	margin-block-end: 1rem !important;
}

.mb-2 {
	margin-block-end: 2rem !important;
}

.mb-3 {
	margin-block-end: 3rem !important;
}

.mb-4 {
	margin-block-end: 4rem !important;
}

.mb-5 {
	margin-block-end: 5rem !important;
}
