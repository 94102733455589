.figure-parallax {
    pointer-events: none;
    position: absolute;
    width: 100%;
    max-width: 18vw;
    z-index: 1;
    // @include effect();

    @include media-xl-down {
        max-width: 14vw;
    }

    @include media-lg-down {
        display: none;
    }

    @include media-md-down {
        display: none;
    }
}

.logo-stahl {
    width: 100%;
    
    .cls-1 {
        fill: var(--text);
        @include effect();
    }

    &:hover {
        .cls-1 {
            fill: #004f9f;
        }
    }
}

.logo-wvmetalle {
    width: 100%;
    
    .cls-1 {
        fill: var(--text);
        @include effect();
    }
    
    .cls-2 {
        fill: var(--text);
        @include effect();
    }

    &:hover {
        .cls-1 {
            fill: #e84136;
        }
    
        .cls-2 {
            fill: #20374b;
        } 
    }
}

[data-parallax-inline="left"] {
    left: -25vw;
    padding-left: 3rem;

    @include media-xl-down {
        left: -17vw;
        padding-left: 2rem;
    }
}

[data-parallax-inline="right"] {
    right: -25vw;
    padding-right: 3rem;

    @include media-xl-down {
        right: -17vw;
        padding-right: 2rem;
    }
}

[data-parallax-block="mid"] {
    top: 50%;
}

[data-parallax-block="bottom"] {
    top: 100%;
}

.figure {
    margin: 0;
    padding: 0;

    &--md {
        max-width: 640px;
    }

    &--sm {
        max-width: 480px;
    }

    &--xs {
        max-width: 320px;
    }

    .img {
        width: 100%;
        height: auto;
    }

    .figcaption {
        margin-block-start: .5rem;
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        color: var(--meta);
    }
}

[data-img-size="1/1"] {
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    // height: 100%;
}

[data-img-size="16/9"] {
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    width: 100%;
    // height: 100%;
}

[data-img-position="top"] {
    object-position: center 0 !important;
}

[data-img-position="top-mid"] {
    object-position: center 25% !important;
}

[data-img-position="mid-bottom"] {
    object-position: center 75% !important;
}

[data-img-position="bottom"] {
    object-position: center 100% !important;
}