//Grid System
$breakpoint-xl: 1500px;
$breakpoint-lg: 1200px;
$breakpoint-md: 900px;
$breakpoint-sm: 600px;

@mixin media-xl-down {
	@media screen and (max-width: #{$breakpoint-xl - 1px}) {
		@content;
	}
}

@mixin media-lg-down {
	@media screen and (max-width: #{$breakpoint-lg - 1px}) {
		@content;
	}
}

@mixin media-md-down {
	@media screen and (max-width: #{$breakpoint-md - 1px}) {
		@content;
	}
}

@mixin media-sm-down {
	@media screen and (max-width: #{$breakpoint-sm - 1px}) {
		@content;
	}
}

@include media-md-down {
	:root {
		--margin-xxlg: 48px;
		--margin-xlg: 40px;
	}
}