.accordion {
	$this: &;
	margin-bottom: 3rem;

	@include media-md-down {
		margin-top: 1rem;
	}

	&__item--show #{$this}__info {
		height: auto;
		opacity: 1;
	}

	&__button {
		cursor: pointer;
		display: block;
		position: relative;
		background: none;
		border: 0;
		width: 100%;
		padding: 1.25rem 3rem 1.25rem 1.5rem;
		font-family: var(--font-main);
		@include fluid-type($min_width, $max_width, 16px, 24px);
		font-weight: 600;
		line-height: 1.4;
		color: var(--title);
		text-align: left;
		background-color: $white;
		@include effect();

		@include media-md-down {
			padding: 1rem 3rem 1rem 1.25rem;
		}

		&::after {
			align-self: flex-start;
			flex-shrink: 0;
			position: absolute;
			top: 1.5rem;
			right: 1.75rem;
			content: "";
			width: 26px;
			height: 26px;
			margin-inline-start: 1rem;
			background-color: var(--text);
			mask-image: url("../img/icon/icon-plus.svg");
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 16px;
			@include effect();

			@include media-md-down {
				top: 1rem;
			}

			@include media-sm-down {
				right: 1rem;
				width: 24px;
				height: 24px;
			}
		}
	}

	&__info {
		height: 0;
		opacity: 0;
		padding: 0 1.5rem;
		background-color: transparent;
		overflow: hidden;
		@include effect();

	}

	&__item {
		margin-block-end: 1rem;

		&#{$this}__item--show {
			#{$this}__button {
				color: var(--color-purple);

				&::after {
					mask-image: url("../img/icon/icon-plus.svg");
					rotate: 45deg;
				}
			}

			#{$this}__info {
				padding: 2rem 1.5rem 3rem;
			}
		}
	}
}
