:root {
	--section-pt: 150px;
	--container-width: 800px;
}

main {
	display: block; //IE9-11 Support
}

.section {
	width: 100%;
	padding-block: var(--section-pt);
	@include effect;

	@include media-lg-down {
		--section-pt: 125px;
	}

	@include media-md-down {
		--section-pt: 100px;
	}

	@include media-sm-down {
		--section-pt: 75px;
	}

	&[data-mode="minHeight"] {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		height: 100%;
		min-height: calc(var(--vh, 1vh) * 100);

		@include media-md-down {
			height: initial;
			min-height: initial;
		}
	}

	&[data-bg="purple"] {
		background-color: var(--color-purple);
	}

	&[data-bg="green"] {
		background-color: var(--color-green);
	}

	&[data-bg="red"] {
		background-color: var(--color-red);
	}

	&[data-bg="yellow"] {
		background-color: var(--color-yellow);
	}

	&[data-bg="blue"] {
		background-color: var(--color-blue);
	}

	&-top {
		@include media-md-down {
			padding-top: 120px;
		}
	}
}

.wrapper {
	padding-inline: 3rem;
	width: 100%;
	will-change: transform, opacity;
	transition: all .35s ease-out;
	z-index: 3;

	// &.active {
	// 	opacity: 0;
	// 	transform: translate(-10vw, 0px);
	// }

	@include media-md-down {
		padding-inline: 2rem;
	}

	@include media-sm-down {
		padding-inline: 1rem;
	}
}

.container {
	position: relative;
	margin-inline: auto;
	max-width: var(--container-width);

	@include media-md-down {
		--container-width: 640px
	}
}

.content,
.content-detail {
	position: relative;

	h2,
	h3,
	h4,
	h5 {
		&:not(:first-child) {
			margin-block-start: 3rem;
		}
	}

	a {
		text-decoration: underline;
		color: var(--text);

		&:hover {
			text-decoration: none;
		}
	}

	ol {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			position: relative;
			display: flex;
			// align-items: center;
			counter-increment: section;

			&:not(:last-child) {
				margin-block-end: 2rem;
			}

			&::before {
				align-self: baseline;
				flex-shrink: 0;
				content: counter(section);
				position: relative;
				display: inline-block;
				text-align: right;
				width: 60px;
				margin-inline-end: 2rem;
				color: var(--color-purple);
				font-family: var(--font-title);
				font-size: 6rem;
				font-weight: 600;
				line-height: 1;

				@include media-md-down {
					margin-inline-end: 1rem;
					font-size: 4rem;
					width: 40px;
				}
			}
		}
	}

	p {
		&.truncated {
			overflow: hidden;
			display: -webkit-box;
			// -webkit-box-orient: vertical;
			// -webkit-line-clamp: 5;
		}
	}

	div {
		&.truncated {
			&>p:not(:first-child) {
				display: none;
			}

			&>div {
				display: none;
			}
		}
	}
}

.content-detail {
	h1, h2 {
		font-family: var(--font-main);
	}
	
	h1 {
		margin: 0 0 3rem 0;
		@include fluid-type($min_width, $max_width, 32px, 40px);
		line-height: 1.4;
	}
	
	h2 {
		margin: 0 0 1rem 0;
		@include fluid-type($min_width, $max_width, 20px, 28px);
		line-height: 1.4;
	}

	h3 {
		margin: 0 0 1rem 0;
		@include fluid-type($min_width, $max_width, 18px, 22px);
		line-height: 1.4;
	}

	ul {
		margin: 0 0 1.5rem 0;

		li {
			position: relative;
			padding-inline-start: 1.5rem;
			@include fluid-type($min_width, $max_width, 16px, 20px);
			
			&:not(:last-child) {
				margin-block-end: .5rem;
			}

			&:before {
				content: "•";
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	p {
		margin: 0 0 .75rem 0;
		@include fluid-type($min_width, $max_width, 16px, 20px);
	}
}

[data-content="invert"] {
	h3,
	p,
	a,
	button {
		color: $white;
	}
}

#scrollTopBtn {
	visibility: hidden;
	display: block;
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 99;
	background-color: #fff;
	color: #333;
	cursor: pointer;
	padding: 15px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: none;
	outline: none;
	box-shadow: 0 3px 6px rgba(0,0,0,.2);
	opacity: 0;
	@include effect();

	&:hover {
		background-color: #eee;
	}

	&.show {
		visibility: visible;
		opacity: 1;
	}

	@include media-sm-down {
		bottom: 1rem;
		right: 1rem;
	}
}

.icon {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	background-color: #000;

	&.icon-arrow-up {
		mask-image: url('../img/icon/icon-chevron-left.svg');
		transform: rotate(90deg);
	}
}