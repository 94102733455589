.swiper-gallery {
   &-container {
      position: relative;
   }

   &__box {
      margin-inline: auto;
      display: grid;
      place-items: center;
      padding: 3rem;
      width: 100%;
      max-width: 480px;
      aspect-ratio: 1/1;
      text-align: center;
      background-color: $white;

      @include media-md-down {
         aspect-ratio: auto;
         min-height: 220px;
         padding: 1.5rem;
      }
   }

   &__box-image {
      margin-inline: auto;
      display: grid;
      place-items: center;
      width: 100%;
      max-width: 640px;
      aspect-ratio: 1/1;
      text-align: center;
      background-color: transparent;
   }

   &__controller {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-block-start: 4rem;
      width: 100%;

      @include media-sm-down {
         margin-block-start: 2rem;
      }
   }

   &__pagination {
      display: flex;
      text-align: center;
      margin-inline: 2rem;

      &.swiper-pagination-horizontal {
         bottom: initial;
         width: initial;
      }

      .swiper-pagination-bullet {
         margin: 0 .75rem !important;
         width: 1.5rem;
         height: 1.5rem;
         border-radius: 50vw;
         background-color: $white;
         opacity: 1;
         @include effect();

         @include media-sm-down {
            margin: 0 .5rem !important;
            width: 1rem;
            height: 1rem;
         }

         &:hover {
            opacity: 1;
         }
      }

      .swiper-pagination-bullet-active {
         background-color: var(--color-red);
         opacity: 1;
      }
   }

   &__prev,
   &__next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: 100px;
      height: 100px;
      background-color: var(--color-red);
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      border: 0;
      @include effect();

      &:hover {
         background-color: var(--color-red);
      }

      &.swiper-button-disabled {
         opacity: .2;
      }

      @include media-md-down {
         display: none;
      }
   }

   &__prev {
      left: 0;
      translate: -100% -50%;
      mask-image: url('../img/icon/icon-chevron-left.svg');
   }

   &__next {
      right: 0;
      translate: 100% -50%;
      mask-image: url('../img/icon/icon-chevron-right.svg');
   }

}
