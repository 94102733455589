.footer {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    will-change: transform, opacity;
    transition: all .35s ease-out;

    &.active {
        opacity: 0;
        transform: translate(100vw, 0px);
    }

    @include media-md-down {
        padding-inline: 2rem;
    }

    @include media-sm-down {
        padding-inline: 1rem;
    }
}

.footer-meta {
    margin-block-end: 4rem;

    &__list {
        display: flex;
        justify-content: center;
    }

    &__item {
        margin-inline: 1rem;
    }

    &__link {
        color: var(--text);
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
        }
    }

    @include media-sm-down {
        margin-block-end: 2rem;

        &__list {
            flex-direction: column;
            align-items: center;
        }

        &__item {
            margin-inline: initial;
            margin-block: .25rem;
        }
    }
}

.footer-logo {
    &__list {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-sm-down {
            flex-direction: column;
        }
    }

    &__item {
        position: relative;
        padding: 4rem;

        @include media-md-down {
            padding: 2rem;
        }

        @include media-sm-down {
            padding-inline: inherit;
        }
    }

    &__link {
        text-decoration: none;
    }

    &__img {
        width: 100%;
        max-width: 480px;
        height: auto;

        @include media-sm-down {
            max-width: 240px;
        }
    }
}