.nav-toggle {
  $this: &;
  cursor: pointer;
  position: fixed;
  top: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: $white;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  @include effect();

  @include media-sm-down {
    top: 1rem;
    right: 1rem;
  }

  &--active {
    transform: rotate(-180deg);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    .nav-toggle-patty:nth-child(1) {
      transform: translate(10px, 3px) rotate(45deg) scale(0.6, 1);
    }

    .nav-toggle-patty:nth-child(3) {
      transform: translate(10px, -3px) rotate(-45deg) scale(0.6, 1);
    }
  }

  &:hover {
    background-color: #eee;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &-patty {
    will-change: transform, opacity;
    display: block;
    width: 30px;
    height: 3px;
    margin: 0 auto 5px auto;
    background-color: var(--text);
    transform: translate(0) rotate(0deg);
    @include effect();

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.nav-menu {
  visibility: hidden;
  position: fixed;
  inset: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  z-index: 990;

  &--active {
    visibility: visible;
  }

  nav {
    padding: 2rem;
    width: 100%;
    max-width: 640px;

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      &:not(:last-child) {
        margin-block-end: 2rem;

        @include media-sm-down {
          margin-block-end: 1rem;
        }
      }

      &:nth-child(1) {
        a:hover {
          color: var(--color-purple);
        }
      }

      &:nth-child(2) {
        a:hover {
          color: var(--color-green);
        }
      }

      &:nth-child(3) {
        a:hover {
          color: var(--color-blue);
        }
      }

      &:nth-child(4) {
        a:hover {
          color: var(--color-red);
        }
      }

      &:nth-child(5) {
        a:hover {
          color: var(--color-yellow);
        }
      }
    }

    a {
      text-decoration: none;
      @include fluid-type($min_width, $max_width, 18px, 32px);
      font-weight: 600;
      color: var(--text);
      text-transform: uppercase;
      line-height: 1.2;
      @include effect();

      // &:hover {
      //   text-decoration: underline;
      // }
    }
  }

  .nav-logos {
    margin-block-start: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-sm-down {
      flex-direction: column;
      align-items: start;
    }

    a {
      width: calc(50% - 1rem);
      max-width: 320px;

      @include media-sm-down {
        width: 100%;
        max-width: 240px;

        &:not(:last-child) {
          margin-block-end: 1rem;
        }
      }
    }
  }
}

.nav-overlay {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50vw;
  background-color: $white;
  z-index: -1;
}