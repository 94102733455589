.toggle {
	$this: &;
	margin-bottom: 3rem;

	@include media-md-down {
		margin-top: 1rem;
	}

	&__item--show #{$this}__info {
		height: auto;
		opacity: 1;
	}

	&__button {
		height: auto;
		opacity: 1;
		cursor: pointer;
		display: block;
		padding: 0;
		position: relative;
		background: none;
		border: 0;
		width: 100%;
		font-family: var(--font-main);
		@include fluid-type($min_width, $max_width, 16px, 24px);
		font-weight: 600;
		line-height: 1.4;
		text-decoration: underline;
		text-align: left;
		@include effect();
	}

	&__info {
		height: 0;
		opacity: 0;
		padding: 0;
		background-color: transparent;
		overflow: hidden;
		@include effect();
	}

	&__item {
		margin-block-end: 1rem;

		&#{$this}__item--show {
			#{$this}__button {
				// color: var(--color-purple);
				height: 0;
				opacity: 0;
				padding: 0;
				background-color: transparent;
				overflow: hidden;
				@include effect();
			}

			#{$this}__info {
				padding: 0;
			}
		}
	}
}
