/* MangoGrotesque */

@font-face {
     font-family: 'Mango Grotesque';
     font-weight: 400;
     font-style: normal;
     text-rendering: optimizeLegibility;
     src: url('../fonts/mango-grotesque/MangoGrotesque-Regular.eot');
     src: local(''),
          url('../fonts/mango-grotesque/MangoGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
          url('../fonts/mango-grotesque/MangoGrotesque-Regular.woff') format('woff'),
          url('../fonts/mango-grotesque/MangoGrotesque-Regular.woff2') format('woff2'),
          url('../fonts/mango-grotesque/MangoGrotesque-Regular.ttf') format('truetype');
}

@font-face {
     font-family: 'Mango Grotesque';
     font-weight: 600;
     font-style: normal;
     text-rendering: optimizeLegibility;
     src: url('../fonts/mango-grotesque/MangoGrotesque-Bold.eot');
     src: local(''),
          url('../fonts/mango-grotesque/MangoGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
          url('../fonts/mango-grotesque/MangoGrotesque-Bold.woff') format('woff'),
          url('../fonts/mango-grotesque/MangoGrotesque-Bold.woff2') format('woff2'),
          url('../fonts/mango-grotesque/MangoGrotesque-Bold.ttf') format('truetype');
}


/* lato */
@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 100;
     src: url('../fonts/lato/lato-v23-latin-100.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-100.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-100.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-100.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-100.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-100.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 100;
     src: url('../fonts/lato/lato-v23-latin-100italic.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-100italic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-100italic.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-100italic.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-100italic.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-100italic.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 300;
     src: url('../fonts/lato/lato-v23-latin-300.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-300.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-300.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-300.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-300.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 300;
     src: url('../fonts/lato/lato-v23-latin-300italic.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-300italic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-300italic.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-300italic.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-300italic.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-300italic.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 400;
     src: url('../fonts/lato/lato-v23-latin-regular.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-regular.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-regular.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-regular.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-regular.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 400;
     src: url('../fonts/lato/lato-v23-latin-italic.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-italic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-italic.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-italic.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-italic.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-italic.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 700;
     src: url('../fonts/lato/lato-v23-latin-700.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-700.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-700.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-700.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-700.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 700;
     src: url('../fonts/lato/lato-v23-latin-700italic.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-700italic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-700italic.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-700italic.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-700italic.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-700italic.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: normal;
     font-weight: 900;
     src: url('../fonts/lato/lato-v23-latin-900.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-900.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-900.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-900.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-900.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-900.svg#Lato') format('svg');
}

@font-face {
     font-family: 'Lato';
     font-style: italic;
     font-weight: 900;
     src: url('../fonts/lato/lato-v23-latin-900italic.eot');

     src: local(''),
          url('../fonts/lato/lato-v23-latin-900italic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-v23-latin-900italic.woff2') format('woff2'),
          url('../fonts/lato/lato-v23-latin-900italic.woff') format('woff'),
          url('../fonts/lato/lato-v23-latin-900italic.ttf') format('truetype'),
          url('../fonts/lato/lato-v23-latin-900italic.svg#Lato') format('svg');
}