$purple: #860587;
$purple-2: #872fb3;
$yellow: #eee117;
$green: #00cb93;
$red: #ff2d72;
$blue: #00ccff;

$white: #FFFFFF;
$black: #000000;

:root {
	--color-purple: rgb(134, 5, 135); //#860587
	--color-purple-2: rgb(135, 47, 179); //#872fb3
	--color-yellow: rgb(238, 225, 23); //#eee117
	--color-green: rgb(0, 203, 147); //#00cb93
	--color-red: rgb(255, 45, 114); //#ff2d72
	--color-blue: rgb(0, 204, 255); //#00ccff

	--title: var(--color-tertiary);
	--text: rgb(0, 0, 0);
	--link: var(--color-secondary);

	--font-title: "Mango Grotesque", Arial, sans-serif;
	--font-main: "Lato", Arial, sans-serif;
	--font-size: 20px;
	--font-weight: 400;
	--line-height: 1.5;
}

//Transition general
@mixin effect($class: all, $time: 150ms, $easing: ease-in-out) {
	-webkit-transition: $class $time $easing;
	-moz-transition: $class $time $easing;
	-o-transition: $class $time $easing;
	transition: $class $time $easing;
}

//Hyphenate
@mixin hyphenate() {
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

//Fluid Font
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

$min_width: 600px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 20px;
