.btn-more {
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    font-size: var(--font-size);
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    color: var(--text);

    &.white {
        color: white;
    }

    @include media-md-down {
        font-size: 16px;
    }

    &:hover {
        text-decoration: none;
    }
}

.btn-back {
    $this: &;
    display: block;
    cursor: pointer;
    position: fixed;
    top: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-color: $white;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, .2);
    transform: rotate(-180deg);
    z-index: 1000;
    @include effect();

    @include media-sm-down {
        top: 1rem;
        right: 1rem;
    }

    &:hover {
        background-color: #eee;
    }

    &:hover,
    &:focus {
        outline: none;
    }

    &-patty {
        will-change: transform, opacity;
        display: block;
        width: 30px;
        height: 3px;
        margin: 0 auto 5px auto;
        background-color: var(--text);
        transform: translate(0) rotate(0deg);
        translate: 0 20px;
        @include effect();

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(1) {
            transform: translate(10px, 3px) rotate(45deg) scale(0.6, 1);
        }

        &:nth-child(3) {
            transform: translate(10px, -3px) rotate(-45deg) scale(0.6, 1);
        }
    }

}